<template>
  <component :is="tag" v-if="carouselNews?.length" class="collection-news">
    <div class="section-inner">
      <h2 v-if="blok.title" class="size-xl title align-center margin-bottom">
        {{ blok.title }}
      </h2>
      <div class="carousel-container carousel-full-width">
        <GenericCarousel
          v-if="carouselNews?.length"
          :items="carouselNews"
          :slides-per-view="'auto'"
          :space-between="14"
          class="carousel-navigation-bottom"
          child-classes="news-card"
          :child-level="3"
          :child-centered="false"
          :size-hint="40"
        />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  collectionNews: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const config = useRuntimeConfig();
const newsOptions = ref({});
const hubOptions = ref({});
const newsList = ref([]);
const newsHub = ref(null);
const folderName = props.blok.is_facility_news
  ? props.blok.facility_news_folder_name?.full_slug?.slice(0, -1)
  : props.blok.folder_name?.full_slug?.slice(0, -1);

if (!props.collectionNews?.length && folderName) {
  newsOptions.value = {
    version: config.public["storyblokVersion"],
    starts_with: folderName,
    content_type: props.blok.is_facility_news ? "news" : "page-news-global",
    resolve_relations: [
      "collection-news.folder_name",
      "collection-news.facility_news_folder_name",
    ].join(","),
    sort_by: "content.overview_published_date:desc",
  };

  hubOptions.value = {
    version: config.public["storyblokVersion"],
    content_type: props.blok.is_facility_news
      ? "page-facility-news-hub"
      : "page-news-global-hub",
    starts_with: folderName,
  };

  newsList.value = await useCustomAsyncStoryblok("", newsOptions.value)
    .then((response) => response.value.stories)
    .catch((error) => {
      console.log(error);
    });

  newsHub.value = await useCustomAsyncStoryblok("", hubOptions.value)
    .then((response) => response.value?.stories[0])
    .catch((error) => {
      console.log(error);
    });
}

const carouselNews = computed(() => {
  return props.collectionNews?.length
    ? props.collectionNews
    : folderName
    ? getCollectionNews(
        newsList.value,
        { showAllCard: true, isFacilityNews: props.blok.is_facility_news },
        newsHub.value
      )
    : null;
});
</script>

<style lang="scss">
.background-collection-news.alternate {
  @include pair-5;

  .swiper-button-prev,
  .swiper-button-next {
    &::before {
      background-color: var(--solid-05);
    }
  }

  .news-card:not(.is-special) {
    @include pair-7;
  }
}
</style>

<style lang="scss" scoped>
:deep(.news-card):not(.is-special) {
  @include pair-5;
  width: 18rem;

  @include for-tablet-portrait-up {
    width: 100%;
    flex-direction: row;
    padding: 0.625rem 2.75rem 0.625rem 0.625rem;
    gap: 5%;
    align-items: flex-start;
  }

  @include for-desktop-up {
    padding-right: 7.62rem;
  }

  .media {
    @include for-tablet-portrait-up {
      width: 21.875rem;
    }
    @include for-desktop-up {
      width: 33.25rem;
    }
    img {
      aspect-ratio: 1.33;
    }
  }

  .text-cta-wrapper {
    width: 100%;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    @include for-tablet-portrait-up {
      width: 14.75rem;
      max-width: none;
      margin: 0;
      height: 100%;
      justify-content: space-between;
    }
    @include for-tablet-landscape-up {
      width: 15.75rem;
    }
    @include for-desktop-up {
      width: 24.75rem;
    }
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100%;

    @include for-desktop-up {
      padding-left: 0;
      padding-right: 0;
    }

    .title {
      order: 1;
      margin-top: 0.75rem;

      @include for-desktop-up {
        margin-top: 1.5rem;
      }
    }

    .title + p {
      color: var(--text-70);
      order: 0;
      margin-top: 0;
    }

    p,
    ol,
    ul {
      order: 2;
      margin-top: 0.5rem;
    }

    p:nth-child(3) {
      @include truncate(4);
    }
  }

  ol,
  ul {
    margin-left: 1rem;
  }

  ul {
    list-style: disc;
  }

  &.is-magazine .text-wrapper {
    .title,
    p,
    ul,
    .title + p {
      order: initial;
    }
  }

  .simple-button {
    text-decoration: underline;
    font-size: 1rem;
    min-height: 0;
    max-width: 37.5rem;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @include for-tablet-portrait-up {
      margin: 0;
    }
    @include for-desktop-up {
      font-size: 1.125rem;
    }

    &.no-label {
      display: none;
    }
  }
}

:deep(.is-special) {
  width: 18rem;
  @include for-tablet-landscape-up {
    width: 22rem;
  }
  @include for-desktop-up {
    width: calc(90vw / 3 - 15px);
  }
  .text-cta-wrapper {
    align-items: center;
    text-align: center;
  }
}

.carousel-container {
  :deep(.swiper-slide) {
    @include for-tablet-portrait-up {
      width: 70%;
    }
    @include for-tablet-landscape-up {
      width: 78%;
    }
  }
}
</style>
